import React, { useState } from "react";
import Logo from "./images/namelogo.png";
import "./styles/Navbar.css";

const Navbar = ({ setActiveSection }) => {
	const [activeLink, setActiveLink] = useState(null);

	const handleLinkClick = (section) => {
		setActiveSection(section);
		setActiveLink(section);
	};

	return (
		<nav className="navbar">
			<div className="logospacer">
				<div className="logocontainer">
					<button
						className={`navlinkspecial ${
							activeLink === "Home" ? "active" : ""
						}`}
						onClick={() => handleLinkClick("Home")}
					>
						{/* <img src={Logo} alt="RDS Logo" className="navbarlogo" /> */}
						<svg
							id="Layer_1"
							className="logosvg"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 797.3 65.52"
						>
							<path d="M28.58,1.41h11.09l20.68,50.85c2.63,6.77,5.61,10.69,8.93,11.75h-25.29c1-.12,1.83-.56,2.49-1.32.66-.75.99-1.79.99-3.1,0-.94-1.13-4.42-3.38-10.43h-23.97c-1.94,5.33-2.91,8.65-2.91,9.96,0,1.5.39,2.67,1.18,3.48.78.82,1.68,1.29,2.68,1.41H0c1.25-.19,2.68-1.32,4.28-3.38,1.6-2.07,3.12-4.95,4.56-8.65L28.58,1.41ZM40.89,40.98l-8.93-23.12-8.74,23.12h17.67Z" />
							<path d="M95.31,12.31c0-6.02-1.91-9.65-5.73-10.9h27.54c6.7,0,12.08,1.76,16.12,5.26,4.04,3.51,6.06,7.87,6.06,13.07,0,4.39-1.36,8.21-4.09,11.47-2.73,3.26-6.47,5.2-11.23,5.83,1.63,1,3.2,2.82,4.7,5.45l8.37,14.48c1.19,2.13,2.55,3.82,4.09,5.08,1.53,1.25,2.9,1.91,4.09,1.97h-19.08l-10.43-19.27c-1.13-2.13-2.13-3.56-3.01-4.28-.88-.72-1.94-1.08-3.2-1.08h-1.32v14.19c0,2.38.25,4.32.75,5.83.5,1.5,1.11,2.62,1.83,3.34.72.72,1.46,1.14,2.21,1.27h-22.65c.81-.12,1.61-.55,2.4-1.27.78-.72,1.41-1.85,1.88-3.38.47-1.54.71-3.46.71-5.78V12.31ZM114.68,30.93c3.51,0,6.25-.97,8.22-2.91,1.97-1.94,2.96-4.57,2.96-7.9,0-2.94-1.02-5.37-3.06-7.29-2.04-1.91-4.78-2.87-8.23-2.87h-6.49v20.96h6.58Z" />
							<path d="M171.64,11.56c0-5.7-1.63-9.09-4.89-10.15h23.12c-.81.19-1.58.63-2.3,1.32-.72.69-1.33,1.79-1.83,3.29-.5,1.5-.75,3.35-.75,5.55v42.77c0,2.76.44,5,1.32,6.72.88,1.72,2.04,2.71,3.48,2.96h-23.03c1.44-.19,2.62-1.13,3.53-2.82.91-1.69,1.36-3.98,1.36-6.86V11.56Z" />
							<path d="M255.58,12.31c0-6.02-1.91-9.65-5.73-10.9h27.54c6.7,0,12.08,1.76,16.12,5.26,4.04,3.51,6.06,7.87,6.06,13.07,0,4.39-1.36,8.21-4.09,11.47-2.73,3.26-6.47,5.2-11.23,5.83,1.63,1,3.2,2.82,4.7,5.45l8.37,14.48c1.19,2.13,2.55,3.82,4.09,5.08,1.53,1.25,2.9,1.91,4.09,1.97h-19.08l-10.43-19.27c-1.13-2.13-2.13-3.56-3.01-4.28-.88-.72-1.94-1.08-3.2-1.08h-1.32v14.19c0,2.38.25,4.32.75,5.83.5,1.5,1.11,2.62,1.83,3.34.72.72,1.46,1.14,2.21,1.27h-22.65c.81-.12,1.61-.55,2.4-1.27.78-.72,1.41-1.85,1.88-3.38.47-1.54.71-3.46.71-5.78V12.31ZM274.95,30.93c3.51,0,6.25-.97,8.22-2.91,1.97-1.94,2.96-4.57,2.96-7.9,0-2.94-1.02-5.37-3.06-7.29-2.04-1.91-4.78-2.87-8.23-2.87h-6.49v20.96h6.58Z" />
							<path d="M356.35,65.42c-9.09,0-16.47-2.96-22.14-8.88-5.67-5.92-8.51-13.55-8.51-22.89,0-6.2,1.39-11.89,4.18-17.06,2.79-5.17,6.72-9.23,11.8-12.17,5.08-2.94,10.68-4.42,16.83-4.42,9.02,0,16.34,2.96,21.95,8.88,5.61,5.92,8.41,13.55,8.41,22.89s-3.04,17.52-9.12,23.97c-6.08,6.46-13.88,9.68-23.41,9.68ZM358.51,55.74c4.95,0,8.98-1.96,12.08-5.88,3.1-3.92,4.65-9.1,4.65-15.56,0-7.39-1.72-13.38-5.17-17.95-3.45-4.57-8.08-6.86-13.91-6.86-4.95,0-8.98,1.9-12.08,5.69-3.1,3.79-4.65,8.98-4.65,15.56,0,7.58,1.69,13.65,5.08,18.19,3.38,4.54,8.05,6.81,14.01,6.81Z" />
							<path d="M413.88,42.96c3.95,8.77,10.25,13.16,18.89,13.16,2.88,0,5.36-.62,7.43-1.88,2.07-1.25,3.1-3.01,3.1-5.26,0-2-.82-3.82-2.45-5.45-1.63-1.63-4.32-3.35-8.08-5.17-7.46-3.38-12.42-6.52-14.9-9.4-2.48-2.88-3.71-6.27-3.71-10.15,0-5.64,2.05-10.17,6.16-13.58,4.1-3.42,9.54-5.12,16.31-5.12,3.01,0,5.91.41,8.7,1.22,2.79.82,5.03,1.82,6.72,3.01v14.95c-1.63-2.88-3.95-5.28-6.96-7.19-3.01-1.91-6.11-2.87-9.31-2.87s-5.47.66-7,1.97c-1.54,1.32-2.3,2.95-2.3,4.89,0,1.75.71,3.37,2.12,4.84,1.41,1.47,4.75,3.4,10.01,5.78,6.89,3.13,11.55,6.2,13.96,9.21s3.62,6.58,3.62,10.72c0,5.64-2.12,10.19-6.34,13.63-4.23,3.45-10.04,5.17-17.44,5.17s-13.38-1.57-18.52-4.7v-17.77Z" />
							<path d="M485.69,11.94c0-2.76-.5-5.09-1.5-7-1-1.91-2.44-3.09-4.32-3.52h42.58v16.73c-.88-3.01-2.26-5.12-4.14-6.34-1.88-1.22-4.32-1.83-7.33-1.83h-11.84v18.14h10.34c3.95.06,6.45-1,7.52-3.2v14.85c-.13-.81-.8-1.53-2.02-2.16-1.22-.63-2.99-.94-5.31-.94h-10.53v18.71h12.78c3.95,0,6.94-.64,8.98-1.93,2.04-1.28,3.93-3.59,5.69-6.91l-3.01,17.48h-42.96c.81-.12,1.6-.58,2.35-1.36.75-.78,1.39-1.96,1.93-3.53.53-1.56.8-3.67.8-6.3V11.94Z" />
							<path d="M554.59,11.47c0-5.7-2.01-9.06-6.02-10.06h22.84l16.64,37.88,16.45-37.88h20.59c-1.44.25-2.65,1.29-3.62,3.1-.97,1.82-1.46,4.23-1.46,7.24v41.64c0,2.38.25,4.32.75,5.83.5,1.5,1.16,2.66,1.97,3.48.81.82,1.6,1.25,2.35,1.32h-22.37c1.25-.19,2.32-1.17,3.2-2.96.88-1.79,1.32-4.34,1.32-7.66V17.67l-21.15,47.85-21.71-47.75v35.06c0,2.51.31,4.59.94,6.25.62,1.66,1.38,2.87,2.26,3.62.88.75,1.79,1.19,2.73,1.32h-21.43c.94-.12,1.83-.56,2.68-1.32s1.57-1.96,2.16-3.62c.59-1.66.89-3.74.89-6.25V11.47Z" />
							<path d="M674.26,1.41h11.09l20.68,50.85c2.63,6.77,5.61,10.69,8.93,11.75h-25.29c1-.12,1.83-.56,2.49-1.32s.99-1.79.99-3.1c0-.94-1.13-4.42-3.38-10.43h-23.97c-1.94,5.33-2.91,8.65-2.91,9.96,0,1.5.39,2.67,1.17,3.48.78.82,1.68,1.29,2.68,1.41h-21.06c1.25-.19,2.68-1.32,4.28-3.38,1.6-2.07,3.12-4.95,4.56-8.65l19.74-50.57ZM686.57,40.98l-8.93-23.12-8.74,23.12h17.67Z" />
							<path d="M741.18,12.5c0-1.88-.46-3.54-1.36-4.98-.91-1.44-2.07-2.73-3.48-3.85s-2.65-1.88-3.71-2.26h19.93l29.52,36.94V11.75c0-2.26-.23-4.07-.7-5.45-.47-1.38-1.02-2.43-1.65-3.15-.63-.72-1.57-1.3-2.82-1.74h20.4c-1.44.25-2.62,1.29-3.53,3.1-.91,1.82-1.36,4.23-1.36,7.24v52.26h-9.78c-2.26-5.64-5.61-11.15-10.06-16.54l-20.96-25.57v31.87c0,2.32.28,4.21.85,5.69.56,1.47,1.3,2.57,2.21,3.29.91.72,1.77,1.14,2.58,1.27h-20.87c.81-.12,1.6-.56,2.35-1.32s1.35-1.85,1.79-3.29c.44-1.44.66-3.32.66-5.64V12.5Z" />
						</svg>
					</button>
				</div>
			</div>
			<div className="navbarcontainer">
				<ul className="navmenu">
					<li className="navitem">
						<button
							className={`navlinks ${
								activeLink === "Lighting" ? "active" : ""
							}`}
							onClick={() => handleLinkClick("Lighting")}
						>
							Lighting
						</button>
					</li>
					<li className="navitem">
						<button
							className={`navlinks ${
								activeLink === "Cinematography" ? "active" : ""
							}`}
							onClick={() => handleLinkClick("Cinematography")}
						>
							Cinematography
						</button>
					</li>
					<li className="navitem">
						<button
							className={`navlinks ${activeLink === "VFX" ? "active" : ""}`}
							onClick={() => handleLinkClick("VFX")}
						>
							VFX
						</button>
					</li>
					<li className="navitem">
						<button
							className={`navlinks ${
								activeLink === "Graphics" ? "active" : ""
							}`}
							onClick={() => handleLinkClick("Graphics")}
						>
							Graphics
						</button>
					</li>
					<li className="navitem">
						<button
							className={`navlinks ${
								activeLink === "WebDesign" ? "active" : ""
							}`}
							onClick={() => handleLinkClick("WebDesign")}
						>
							Web Design
						</button>
					</li>
					<li className="navitem">
						<button
							className={`navlinks ${activeLink === "Music" ? "active" : ""}`}
							onClick={() => handleLinkClick("Music")}
						>
							Music
						</button>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
