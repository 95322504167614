import React from "react";
import "../../App.css";
import Hero from "../Hero";

function Home() {
	return (
		<>
			<Hero />
		</>
	);
}

export default Home;
